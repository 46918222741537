.LoginForm {
    position: relative;

    & > section {
        @media (--tablet) {
            margin: 96px auto;
            width: 375px;
            background-color: var(--blackLighten60LegacyColor);
        }

        & .ApplicantsLink {
            padding-top: 12px;
            padding-bottom: 40px;

            & p {
                margin-bottom: 8px;
            }

            & a {
                @mixin label;
                font-weight: 400;
            }
        }

        & > div {
            padding: 24px 0;

            @media (--tablet) {
                & input,
                & .Button,
                & .SecondaryButton,
                & label {
                    width: 100%;
                }
            }

            & h2,
            & h1 {
                @mixin h4;
                margin: 0 0 16px;
            }

            & p {
                @mixin p;
            }

            & .Button {
                width: 100%;
            }

            & .SecondaryButton {
                width: 100%;
                margin-top: 59px;
            }

            & label {
                @mixin p;
                margin-bottom: 4px;
                display: block;
                font-weight: 500;
            }

            & input {
                @mixin input;
                display: block;

                &[aria-invalid='true'] {
                    border: var(--errorLegacyColor) solid 1px;
                }
            }

            & a {
                border-color: var(--redLighten40LegacyColor);
            }
        }
    }
}

.CallToAction {
    position: relative;
    background-color: var(--blackLighten60LegacyColor);

    @media (--tablet) {
        &::after {
            position: absolute;
            margin: 0 32px;
            width: calc(100% - 64px);
            height: 1px;
            content: '';
            background-color: var(--blackLighten50LegacyColor);
            bottom: 0;
        }
    }
}

.Inner {
    @mixin wrapper;

    & .FeideDescription {
        margin: 32px 0 8px;
    }
}

.VerifyCode {
    & button {
        margin-top: 57px;
    }

    & .SendCodeViaEmailInfo {
        margin-top: 48px;
    }

    &.VerifyCode .SecondaryButton {
        margin-top: 16px;
    }
}

.Errors,
.Success {
    @mixin label;
    font-weight: 400;
    margin-top: 5px;
    display: block;
}

.Errors {
    color: var(--errorLegacyColor);
}

.Success {
    color: var(--successLegacyColor);
}
